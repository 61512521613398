export const environment = {
  production: false,
  //baseUrl:'https://fleet.website.limited:9898/usd/'
  //baseUrl:'https://api.anstrack.com/',
  // baseUrl:'https://anstrack.com:30405/usd/' 304016
  // baseUrl:'https://anstrack.com:30416/usd/'
  baseUrl:'https://usdservices.in:30416/usd/'



  //baseUrl:'https://fleetqa.website.limited:30409/usd/'
}
