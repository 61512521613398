import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import{UserService} from 'src/app/core/services/user/user.service';
import { fromEvent, Observable, Subscription } from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  title = 'demo1';
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription
  showSidebar: boolean = true;
  showNavbar: boolean = true;
  showFooter: boolean = true;
  isLoading: boolean;

  constructor(private router: Router,public userService:UserService) {
  }

  ngOnInit() {
    // alert(window.screen.height);
    // alert(window.screen.width);
    this.resizeObservable$ = fromEvent(window, 'resize')
  }
}
