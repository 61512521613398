import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import{AuthenticationService} from 'src/app/core/services/authentication/authentication.service';
import {Router} from '@angular/router';
import{UserService} from 'src/app/core/services/user/user.service';
import { AppComponent } from '../../../app.component';
import { ConfirmationDialog } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import { interval } from 'rxjs';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public iconOnlyToggled = false;
  public sidebarToggled = false;
  public currentUserData :any;
  public isMobileView = false;
  public notificationList =[];
  public currentCompanyLogo :any;
  public intervalRef :any;
  public unreadNotificationCount :any;
  
  constructor(config: NgbDropdownConfig,
    public auth:AuthenticationService,
    public appComponent : AppComponent,
    public userService:UserService,
    public dialog:MatDialog,
    public router:Router) {
    config.placement = 'bottom-right';
  }

  ngOnInit() {
    this.currentCompanyLogo = localStorage.getItem('logo');
    this.currentUserData =  this.auth.getLoggedInUser();
    this.listenMobileEvent();
    this.getNotificationList(); 
     this.intervalRef = interval(2*60 *1000).subscribe(x =>{
      if(this.auth.getToken() != null){
        this.getNotificationList();  
        }
     })
  }

  listenMobileEvent(){
    if(/Android|iPhone/i.test(navigator.userAgent)){
      this.isMobileView=true;
    }
    this.appComponent.resizeObservable$.subscribe( (evt:any) => {
      if(/Android|iPhone/i.test(navigator.userAgent)){
        this.isMobileView=true;
      }else{
        this.isMobileView=false;
      }
    })
  }

  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if(this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if(this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }

  // toggle right sidebar
  logoutUser() {
    localStorage.clear();
    this.router.navigate(['/auth/']);
  }

    /*
      Author:Kapil Soni
      Funcion:getAllMultpleDataViaURL
      Summary:getAllMultpleDataViaURL for get vehicle list
      Return list
    */
  getNotificationList() {
    this.userService.getDataByUrl('showAllGeofenceInOutDataData').subscribe((data: any) => {
      if(data.length > 0) {
        this.notificationList = data;
        this.unreadNotificationCount = this.notificationList.filter(x=>x.status == 'UNREAD');
      } else {
        //this.isLoading = false;
      }
    }, error => {
      this.auth.errorToast(error.error.message);
    })
  }

    /*
   Author:Kapil Soni
   Funcion:getAllCompanyData
   Summary:getAllCompanyData for get vehicle list
   Return list
 */
   readUnreadNotification(value) {
     value.status == 'UNREAD' ? value.status = 'READ' : value.status;
      this.userService.updateData(value,'updateGeofenceInOutDataInfo').subscribe((data: any) => {
        if (data.message) {
          this.auth.successToast(data.message);
        }
      })
    }

    ngOnDestroy(){
      if(this.auth.getToken() != null){
        this.intervalRef.unsubscribe();
      }
    }

        /*
      Author:Kapil Soni
      Funcion:deleteVehicle
      Summary:deleteVehicle for get delete vehicle
      Return list
    */
    logoutUserFromWeb(){
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        panelClass: 'custom-dialog-container',
        data: {
          message: 'Are you sure want to logout?',
          logout:true,
          buttonText: {
            ok: 'Yes',
            cancel: 'No',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if(confirmed) {
          this.logoutUser();
        }
      });
    }

    /*
      Author:Kapil Soni
      Funcion:deleteAlert
      Summary:deleteAlert for get delete vehicle
      Return list
    */
    deleteAlert(alert:any){
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        panelClass: 'custom-dialog-container',
        data: {
          message: 'Are you sure want to delete?',
          logout:true,
          buttonText: {
            ok: 'Yes',
            cancel: 'No',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if(confirmed) {
          this.userService.storeDataToDb(alert != '' ? alert :  this.notificationList,alert != '' ? 'clearOneNotification' : 'clearAllNotification').subscribe((data: any) => {
            if(data) {
              this.getNotificationList();
            }
          })
        }
      });
    }
}
